import React, { useCallback, useState } from 'react'

import { Icon, IconName } from '@biogroup/icons'
import { cn } from '@biogroup/utils/cn'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  iconName?: IconName
  withReset?: boolean
  a11yLabels?: {
    clear: string
  }
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      type,
      iconName,
      className,
      onChange,
      withReset = false,
      a11yLabels,
      ...props
    },
    ref,
  ) => {
    const [value, setValue] = useState('')

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value)
        onChange?.(event)
      },
      [onChange],
    )

    const handleReset = useCallback(() => {
      setValue('')
    }, [setValue])

    return (
      <div className="group relative flex">
        {iconName ? (
          <Icon
            size="sm"
            name={iconName}
            className={cn(
              'absolute bottom-0 left-3 top-0 my-auto text-dull-foreground',
              'group-focus-within:text-foreground',
            )}
          />
        ) : null}
        <input
          type={type}
          className={cn(
            'bg-input flex w-full rounded-lg border border-faded px-3 py-2 text-sm font-normal ring-offset-background',
            'placeholder:text-dull-foreground',
            'focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-2',
            'disabled:cursor-not-allowed disabled:bg-muted disabled:text-muted-foreground',
            'file:border-0 file:bg-transparent file:text-sm file:font-medium',
            'aria-[invalid=true]:text-feedback-error',
            'aria-[invalid=true]:border-feedback-error',
            'aria-[invalid=true]:placeholder:text-feedback-error',
            {
              '!pl-9': iconName,
              '!pr-9': withReset,
            },
            className,
          )}
          ref={ref}
          onChange={handleChange}
          {...(type !== 'file' && { value })}
          {...props}
        />
        {withReset && value ? (
          <button
            type="button"
            onClick={handleReset}
            className={cn(
              'absolute bottom-0 right-3 top-0 my-auto flex h-4 w-4 rounded-full text-muted-foreground',
              'focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring',
            )}
          >
            <span className="sr-only">
              {a11yLabels?.clear ? a11yLabels.clear : 'Clear input'}
            </span>
            <Icon name="close" size="sm" />
          </button>
        ) : null}
      </div>
    )
  },
)

Input.displayName = 'Input'

export { Input }
